// @flow

import React, { memo, type Node, type Element } from 'react';
import { Grid, Paper, SvgIcon } from '@material-ui/core';
import { palette } from '@dt/theme';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Info from '@material-ui/icons/Info';
import Flag from '@material-ui/icons/Flag';
import Warning from '@material-ui/icons/Warning';
import Text from './Text';

export type StatusMessageEnum = 'success' | 'info' | 'warning' | 'critical';

type Props = {|
  children: Node,
  status?: StatusMessageEnum,
  icon?: Element<typeof SvgIcon>,
  iconColor?: string,
  heading?: string,
  inline?: boolean,
|};

const statusColors = {
  success: {
    backgroundColor: palette.green50,
    border: palette.green,
    iconColor: palette.green10,
    defaultIcon: <ThumbUp />,
  },
  info: {
    backgroundColor: palette.gray50,
    border: palette.gray,
    iconColor: palette.gray10,
    defaultIcon: <Info />,
  },
  warning: {
    backgroundColor: palette.yellow50,
    border: palette.yellow,
    iconColor: palette.yellow10,
    defaultIcon: <Flag />,
  },
  critical: {
    backgroundColor: palette.red50,
    border: palette.red,
    iconColor: palette.red10,
    defaultIcon: <Warning />,
  },
};

export default memo<Props>(function Banner(props: Props) {
  const config =
    (props.status && statusColors[props.status]) || statusColors.info;

  const iconToUse = props.icon || config.defaultIcon;

  return (
    <Paper
      style={{
        background: config.backgroundColor,
        width: 'auto',
        borderTop: props.inline ? 'none' : `3px solid ${config.border}`,
        padding: props.inline ? '10px' : '16px',
        display: 'flex',
        margin: '5px 0',
        alignItems: 'center',
      }}
      role="status"
      aria-labelledby="status-heading"
      aria-describedby="status-content"
      elevation={props.inline ? 0 : 1}
    >
      <Grid container>
        <Grid
          item
          style={{
            paddingRight: props.inline ? '12px' : '16px',
            alignSelf: 'flex-start',
          }}
        >
          {React.cloneElement(iconToUse, {
            style: {
              color: props.iconColor ? props.iconColor : config.iconColor,
            },
          })}
        </Grid>
        <Grid item>
          {props.heading && (
            <Text
              variant="body"
              style={{ color: palette.gray20 }}
              label={props.heading}
            >
              {props.heading}
            </Text>
          )}
          <Text
            variant="bodyS"
            style={{ color: palette.gray30 }}
            component="div"
          >
            {props.children}
          </Text>
        </Grid>
      </Grid>
    </Paper>
  );
});
