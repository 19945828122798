//@flow
import React, { memo, type Node } from 'react';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { palette } from '@dt/theme';

import Label from './Label';

type Props = {|
  +icon?: Node,
  +label: string,
  +rounded?: boolean,
  +tooltipText?: string,
  +backgroundColor?: string,
|};

const useStyles = makeStyles(() => ({
  container: ({ rounded, backgroundColor }) => ({
    width: 'fit-content',
    padding: '2px 8px',
    borderRadius: rounded ? 16 : 4,
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: 14,
    backgroundColor: backgroundColor ? backgroundColor : palette.gray45,
    color: palette.gray20,
    height: '1.8em',
  }),
  label: ({ icon }) => ({
    marginLeft: icon ? 4 : 0,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 250,
  }),
  icon: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function Tag({
  icon,
  label,
  backgroundColor,
  tooltipText,
  rounded = false,
}: Props) {
  const classes = useStyles({ rounded, icon, backgroundColor });

  const tooltipTitle = typeof tooltipText === 'string' ? tooltipText : label;

  return (
    <Tooltip title={tooltipTitle} aria-label={tooltipTitle}>
      {/* Span is used for Tooltip to register content area. */}
      <span style={{ display: 'inline-flex', whiteSpace: 'nowrap' }}>
        <Label rounded={rounded} variant="transparent">
          <div className={classes.container}>
            <div className={classes.icon}>
              {icon ? icon : <span style={{ height: 14 }} />}
            </div>
            <span className={classes.label}>{label}</span>
          </div>
        </Label>
      </span>
    </Tooltip>
  );
}

export default memo<Props>(Tag);
