//@flow
import React, { memo } from 'react';
import { InputAdornment, Paper, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

type Props = {|
  ariaLabel?: string,
  autoFocus?: boolean,
  fullWidth?: boolean,
  id?: string,
  onChange: (value: string) => void,
  placeholder?: string,
  value: string,
  size?: null | void | 'small' | 'medium',
|};

// TODO: The caller for this component needs to debounce the onChange function. This needs a bit of rework.
export default memo<Props>(function KeywordSearch({
  onChange,
  id,
  ariaLabel,
  placeholder,
  fullWidth,
  value,
  autoFocus,
  size,
}: Props) {
  return (
    <Paper component="form">
      <TextField
        aria-label={ariaLabel}
        variant="outlined"
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        size={size}
        id={id}
        placeholder={placeholder ? placeholder : 'Keyword Search'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={e => onChange(e.target.value)}
        type="search"
        defaultValue={value}
      />
    </Paper>
  );
});
