//@flow
export default ({
  RESTFUL_API: 'RESTFUL_API',
  NETWORK_SERVICE: 'NETWORK_SERVICE',
  CLOUD_RESOURCE: 'CLOUD_RESOURCE',
  WEB_APPLICATION: 'WEB_APPLICATION',
}: {|
  +RESTFUL_API: 'RESTFUL_API',
  +NETWORK_SERVICE: 'NETWORK_SERVICE',
  +CLOUD_RESOURCE: 'CLOUD_RESOURCE',
  +WEB_APPLICATION: 'WEB_APPLICATION',
|});
