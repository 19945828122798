//@flow
import React, { memo, type Node } from 'react';
import { palette, spacing } from '@dt/theme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  box: {
    minWidth: spacing.menuWidth,
    maxWidth: spacing.menuWidth,
    paddingTop: 12,
    borderRight: `1px solid ${palette.accent}`,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
});

type Props = {
  children: Node,
};

export default memo<Props>(function LHSMenu({ children }) {
  const css = useStyles();
  return <div className={css.box}>{children}</div>;
});
