//@flow
import React from 'react';
import logo from '@dt/brand/logo.png';
import { Raven } from '@dt/global';
import { palette } from '@dt/theme';

const styles = {
  container: {
    display: 'flex',
    height: '90vh',
    paddingTop: '10vh',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  inner: {
    width: 200,
  },
  img: {
    width: '100%',
  },
  paragraph: {
    textAlign: 'center',
  },
  button: {
    padding: '8px 16px',
    backgroundColor: palette.brand,
    borderColor: palette.brand,
    color: palette.white,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '14',
    borderRadius: 2,
    margin: '24px auto',
  },
};

const ErrorPage = () => (
  <div style={styles.container}>
    <div style={styles.inner}>
      <img src={logo} style={styles.img} />
      <p style={styles.paragraph}>An error occurred.</p>
      <p style={styles.paragraph}>
        Our support team has been notified automatically.
      </p>
    </div>
    <button
      style={styles.button}
      onClick={() => {
        const lastEventId = Raven.lastEventId();
        Raven.showReportDialog({ eventId: lastEventId });
      }}
    >
      Create Issue Report
    </button>
  </div>
);

export default ErrorPage;
