// @flow
import {
  CloudResourceTypeDefinition,
  type DiscoveredViaType,
  type HostedOn,
  type PolicyRuleTypeRelevanceType,
} from '@dt/horizon-api';
import { type PolicyViolationWithAffectedComponentList } from '../policy_violations/types';
import { type APIOperationsDecoratedList } from '../api_operations/types';

import { type RestfulAPIDecorated } from '../restful_apis/types';
import { type WebApplicationDecorated } from '../web_applications/types';
import { type CloudResourceDecorated } from '../cloud_resources/types';
import { type NetworkServiceDecorated } from '../network_services/types';

export type RequiredDecoratedAssetProps = {|
  id: string,
  name: string,
  url: string,
  hosted_on: HostedOn,
  discovered_via: DiscoveredViaType,
  date_created: string,
  policy_violations_decorated: PolicyViolationWithAffectedComponentList,
  api_operations_decorated?: APIOperationsDecoratedList,
  aggregated_relevance: void | PolicyRuleTypeRelevanceType,

  // This is basically the worst relevance any violation ever had on this asset, regardless if it's resolved or not.
  historic_aggregated_relevance: void | PolicyRuleTypeRelevanceType,
  unresolved_policy_violations_point_value: number,
|};

export const AssetTypeDict = {
  RESTFUL_API: 'RESTFUL_API',
  SINGLE_PAGE_WEB_APP: 'SINGLE_PAGE_WEB_APP',
  API_DOMAIN: 'API_DOMAIN',
  CLOUD_RESOURCE: 'CLOUD_RESOURCE',
};

export const AssetOptionTypeDict = {
  ANY: 'ANY',
  [AssetTypeDict.RESTFUL_API]: AssetTypeDict.RESTFUL_API,
  [AssetTypeDict.SINGLE_PAGE_WEB_APP]: AssetTypeDict.SINGLE_PAGE_WEB_APP,
  [AssetTypeDict.API_DOMAIN]: AssetTypeDict.API_DOMAIN,
  ...CloudResourceTypeDefinition,
};
export type AssetOptionType = $Keys<typeof AssetOptionTypeDict>;

export type UnifiedDecoratedAssetType =
  | RestfulAPIDecorated
  | WebApplicationDecorated
  | CloudResourceDecorated
  | NetworkServiceDecorated;

export type UnifiedDecoratedAssetList = $ReadOnlyArray<UnifiedDecoratedAssetType>;

export type SelectedAssets = {|
  restful_api_ids: Array<string>,
  web_application_ids: Array<string>,
  cloud_resource_ids: Array<string>,
  network_service_ids: Array<string>,
|};

export type SelectedDecoratedAssetListType = {|
  restful_api_ids: {| [string]: UnifiedDecoratedAssetType | void |},
  web_application_ids: {| [string]: UnifiedDecoratedAssetType | void |},
  cloud_resource_ids: {| [string]: UnifiedDecoratedAssetType | void |},
  network_service_ids: {| [string]: UnifiedDecoratedAssetType | void |},
|};
